// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Carousel_Root__AXBki {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.Carousel_Container__S4tDk {
  display: flex;
  width: 100%;
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.Carousel_Slide__uTa4J {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 768px) {
  .Carousel_Root__AXBki {
    aspect-ratio: 16/9;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Carousel.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,cAAc;EACd,WAAW;EACX,YAAY;AACd;;AAEA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":[".Root {\n  width: 100%;\n  height: 100%;\n  position: relative;\n  overflow: hidden;\n}\n\n.Container {\n  display: flex;\n  width: 100%;\n  height: 100%;\n  list-style: none;\n  padding: 0;\n  margin: 0;\n}\n\n.Slide {\n  flex-shrink: 0;\n  width: 100%;\n  height: 100%;\n}\n\n@media screen and (min-width: 768px) {\n  .Root {\n    aspect-ratio: 16/9;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": `Carousel_Root__AXBki`,
	"Container": `Carousel_Container__S4tDk`,
	"Slide": `Carousel_Slide__uTa4J`
};
export default ___CSS_LOADER_EXPORT___;
