import { useEffect } from "react";

// React-router-dom
import { ScrollRestoration } from "react-router-dom";

const Layout = ({ title, children }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <div className="bg-[#fafafa]">
      <ScrollRestoration />
      {children}
    </div>
  );
};

export default Layout;

Layout.defaultProps = {
  title: "Holiday Garden Hotel Directory",
};
